.home__container {
    position: relative;
    @include large {
        flex-direction: column-reverse !important;
    }
}
.sidebar__content {
    position: sticky;
    hr {
        margin: 20px 0;
        border-color: black;
    }
}
.sidebar__achievements {
    display: flex;
    align-items: center;
    font-weight: 700;
    span {
        margin-left: 6px;
        position: relative;
        z-index: 5;
        background-color: $orange;
        border: 4px solid black;
        border-radius: 30px;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
    }
}
.home__greeting {
    position: relative;
    .progressbar__complete::after {
        right: -50px;
        bottom: 4px;
        @media screen and (max-width: 960px) {
            width: 20px;
            height: 20px;
            font-size: 16px;
            bottom: 0;
            right: -25px;
            line-height: 20px;
        }
    }
}
.home__loading__container {
    height: 100%;
    width: 100%;
    position: relative;
}
.home__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 20vw;
}
