.forms__container {
    max-width: 1200px;
    margin: 50px auto;
    padding: 0 50px;

    .MuiFormControlLabel-root {
        color: black;
    }

    legend {
        font-size: 25px;
        color: black;
        margin-bottom: 0.5em;
        font-weight: bold;
    }

    svg {
        color: $dark-blue;
    }

    .MuiFormLabel-root.Mui-focused {
        color: black;
    }

    .MuiButton-containedPrimary {
        float: right;
        margin-bottom: 12px;
    }

    .MuiFormControl-root-MuiTextField-root {
        margin-bottom: 30px;
    }
}
.forms__subtitle {
    margin-bottom: 0.5em;
}
.forms__section {
    margin-bottom: 3.5em;
}
.forms__important__container {
    margin-bottom: 2.5em;
    // margin-top: 5em;
    &::before {
        border-top: dotted 1px black;
        padding-top: 25px;
        content: "";
        width: 80%;
        margin: 0 auto;
        display: block;
    }
    h6 {
        white-space: normal;
    }
}
.forms__important {
    text-align: justify;
    font-weight: 500;
    color: $dark-blue;
}
.forms__content {
    fieldset {
        width: 100%;
        margin-bottom: 4rem;
        .MuiFormControlLabel-root {
            margin-right: 30px !important;
        }
    }
    .forms__removeMargin {
        margin-bottom: 0 !important;
    }
    .forms__comment {
        margin-bottom: 4rem;
    }
    .MuiInputBase-input {
        font-size: 24px;
    }
    .MuiInput-root[type="number"] {
        width: fit-content !important;
    }
}
.forms__radioGroupContainer {
    justify-content: space-between;
    flex-direction: column !important;
}
.forms__numberInput {
    display: block !important;
}
.forms__fullWidth {
    width: 100%;
}
.forms__slider {
    margin: 0 50px;
    width: 90%;
}

// Placeholder text for comment input
.forms__content {
    .MuiGrid-root {
        div {
            fieldset {
                .MuiFormGroup-root {
                    label {
                        // radiobutton
                        .MuiTypography-root {
                            font-size: 24px;
                        }
                    }
                }
                .MuiFormControl-root {
                    label {
                        font-size: 1.5rem;
                    }
                }
                .MuiGrid-root {
                    .MuiGrid-root {
                        label {
                            .MuiTypography-root {
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
                .forms__slider {
                    .MuiSlider-root {
                        .MuiSlider-markLabel {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
            .MuiFormControl-root {
                label {
                    font-size: 1.25rem;
                }
                .MuiInput-root {
                    .MuiInput-input {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}
