.footer {
    background-color: $grey;
}
.footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer__content {
    margin-right: 15px;
}
.footer__links {
    display: flex;
    gap: 30px;
    .footer__links__col1 {
        display: flex;
        gap: 30px;
    }
    h5 {
        color: $dark-blue;
        font-weight: 700;
    }
    .footer__links__col2 {
        display: flex;
        gap: 30px;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            text-decoration-color: $dark-blue;
            text-decoration-thickness: 2px;
        }
    }
}
.footer__legals {
    padding: 6px;
    text-align: center;
    background-color: #dadae9;
}

// Media Queries
@media screen and (max-width: 1224px) {
    .footer__container {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .footer__content {
        white-space: nowrap;
    }
    .footer__logo {
        img {
            width: 100%;
            min-width: 100px;
        }
    }
    .footer__links {
        display: flex;
        flex-direction: row;
    }
    .footer__links__col1 {
        flex-direction: column;
    }
    .footer__links__col2 {
        flex-direction: column;
        margin: 0 30px;
    }
}

@media screen and (max-width: 600px) {
    .footer__links {
        flex-direction: column;
    }
    .footer__links__col2 {
        margin: 0;
    }
}
