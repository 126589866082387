.drawer__container {
    display: flex;
    height: 100%;

    .drawer__toolbar {
        padding-right: 24px;
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 82.5px;
        @media screen and (max-width: 960px) {
            padding-right: 0;
        }
    }

    .drawer__menuButton {
        margin-right: 36px;
    }

    .drawer__menuButton__hidden {
        display: none;
    }

    .drawer__appBar {
        z-index: 1201;
        transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    .drawer__appBar__shift {
        width: calc(100% - 240px);
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        margin-left: 240px;
    }

    .drawer__title {
        flex-grow: 1;
        color: $layout-text;
    }

    .drawer__menu__icon {
        color: white;
        font-size: 42px;
    }

    .drawer__paper {
        width: 240px;
        position: relative;
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        white-space: nowrap;
    }

    .drawer__paper__close {
        width: 72px;
        overflow-x: hidden;
        transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    .drawer__toolbaricon {
        display: flex;
        padding: 0 8px;
        min-height: 64px;
        align-items: center;
        justify-content: flex-end;
        button {
            svg {
                color: white;
                font-size: 2rem;
            }
        }
    }
    .drawer__toolbar__nav {
        .nav__fontSize--placeholder {
            color: white;
        }
        svg {
            color: white;
        }
        .MuiOutlinedInput-notchedOutline {
            // border-color: black !important;
        }
        // .nav__content__language {
        //     button {
        //         color: black;
        //         border: 2px solid black;
        //     }
        // }
    }
    .drawer__nav {
        .drawer__nav__item {
            .MuiTypography-body1 {
                color: white;
            }
            .MuiListItemIcon-root {
                color: white;
            }
            &.active {
                div {
                    background-color: $sea-blue;
                }
            }
        }

        .drawer__nav__subitem {
            padding-top: 0;
            .MuiTypography-body1 {
                color: white;
            }
            .MuiListItemIcon-root {
                font-size: 15px;
                color: white;
            }
            .css-tlelie-MuiListItemText-root {
                font-size: 5px;
            }
            &.active {
                div {
                    background-color: $sea-blue;
                }
            }
        }
    }
}

.drawer__list__mobile {
    width: 250px;
}

.drawer__nav {
    // color: black;

    .drawer__nav__item {
        .MuiListItem-root {
            padding-left: 1.5rem;
        }

        text-decoration: none;

        .MuiTypography-body1 {
            font-weight: 500;
            // color: rgba(0, 0, 0, 0.54);
        }

        svg {
            height: 1.8em;
            width: 1.8em;
            font-size: 20px;
            color: white;
        }
    }

    .MuiTreeItem-content {
        padding-left: 10px;
    }
    .MuiTreeItem-root {
        padding-bottom: 10px;
    }
    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
        background: none;
    }
}

.MuiDrawer-paper {
    background-color: $dark-blue !important;
}

.nav__logo__container {
    width: 100%;
    height: 82.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    a {
        height: 100%;
        position: relative;
        left: 1rem;
    }
    img {
        max-width: 150px;
        padding-left: 2rem;
        padding-top: 0.3rem;
        padding-bottom: 5px;
    }
    // ALPHA
    .MuiChip-root {
        font-weight: bold;
        color: $drawer-color;
        font-size: 10px;
        height: 22px;
        span {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
    .nav__logo {
        // width: 200px;
        max-height: 82.5px;
    }
}
.drawer__menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/*************************PORTAL**************************/
.portal {
    .drawer__paper {
        background-color: $dark-blue !important;
    }
    .nav__logo__container {
        a {
            width: 160px;
            left: 2rem;
        }
    }
    .drawer__nav {
        .drawer__nav__item {
            .MuiTypography-body1 {
                color: white;
            }
            .MuiListItemIcon-root {
                color: white;
            }
            &.active {
                div {
                    background-color: $sea-blue;
                }
            }
        }

        .drawer__nav__subitem {
            padding-top: 0;
            .MuiTypography-body1 {
                color: white;
            }
            .MuiListItemIcon-root {
                font-size: 15px;
                color: white;
            }
            .css-tlelie-MuiListItemText-root {
                font-size: 5px;
            }
            &.active {
                div {
                    background-color: $sea-blue;
                }
            }
        }
    }
}
