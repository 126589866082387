.login__container {
    position: relative;
    top: -250px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form__container {
        background-color: $grey;
        border-radius: 20px;
        padding: 50px;
        width: 80%;
    }
    .login__title {
        font-weight: 400;
        margin-bottom: 30px;
    }

    .login__avatar {
        margin: 0.5rem;
        background-color: $primary;
    }

    form {
        width: "100%"; // Fix IE 11 issue.
        .MuiInputBase-root-MuiOutlinedInput-root,
        .MuiInputBase-root-MuiOutlinedInput-root {
            background-color: white;
        }
        input::placeholder {
            font-style: italic;
        }
        .login__password {
            margin-top: 25px;
        }
    }

    .form__showPasswordContainer {
        height: 100%;
    }

    .form__showPassword {
        margin: 0;
        color: white;
        background-color: black;
    }

    .form__submitButton {
        margin-top: 1rem;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0.5rem;
        background-color: $orange;
        text-transform: capitalize;
        border-radius: 10px;
    }

    .form__optionButton {
        margin-top: 1rem;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0.5rem;
        border: 2px solid black;
        // color: black;
        // background-color: $orange;
        text-transform: capitalize;
        border-radius: 10px;
    }

    .form__backButton {
        margin-top: 1rem;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0.5rem;
        // color: black;
        // background-color: $orange;
        text-transform: capitalize;
        border-radius: 10px;
    }

    .form__forgotPasswordButton {
        color: $dark-blue;
        text-decoration: underline;
        text-transform: capitalize;
        font-weight: 700;
    }
}

.login__backdrop {
    z-index: 1201;
    background: $layout-text;
    .login__spinner {
        position: absolute;
    }
}

// Dashboard scss
.dashboard__login__container {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .login__avatar {
        margin: 0.5rem;
        background-color: $primary;
    }

    form {
        width: "100%"; // Fix IE 11 issue.
        margin: 0.5rem;
    }

    button {
        margin-top: 1rem;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0.5rem;
    }
}

.login__backdrop {
    z-index: 1201;
    background: $layout-text;
    .login__spinner {
        position: absolute;
    }
}
