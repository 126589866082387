.nav {
    background-color: black;
    height: 110px;
}
.nav__container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    .nav__logo {
        width: 100%;
        display: inline-block;
        margin-right: 20px;
    }
}
.nav__content {
    display: flex;
    align-items: center;
    gap: 20px;
    .nav__content__theme {
        @media screen and (max-width: 960px) {
            display: none;
        }
    }
    @media screen and (max-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        margin-top: 30px;
        .nav__content__login {
            grid-row: 1;
            grid-column: 1 / 3;
            margin-left: 8px;
            a {
                width: 100%;
                display: inline-grid;
            }
        }
    }
}
.nav__theme__drawer {
    @media screen and (min-width: 960px) {
        display: none;
    }
}
.nav__content__fontSize {
    div {
        label {
            color: white;
        }
        svg {
            color: white;
        }
        fieldset {
            border-color: white;
            border-width: 2px;
            border-radius: 10px;
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: white !important;
        }
        .MuiSelect-select {
            color: white;
        }
    }
}

// Select dropdown fix
.MuiList-root {
    display: flex;
    flex-direction: column;
}

.nav__content__theme {
    button {
        @media screen and (max-width: 960px) {
            width: 40px;
        }
        img {
            @media screen and (max-width: 960px) {
                width: 100%;
            }
        }
    }
}

// Font Size select
.nav__fontSize {
    &--placeholder {
        font-size: 30px;
        @media screen and (max-width: 960px) {
            font-size: 17px;
        }
    }
    &--font1 {
        font-size: 30px;
        @media screen and (max-width: 960px) {
            font-size: 17px;
        }
    }
    &--font2 {
        font-size: 40px;
        @media screen and (max-width: 960px) {
            font-size: 20px;
        }
    }
    &--font3 {
        font-size: 50px;
        @media screen and (max-width: 960px) {
            font-size: 24px;
        }
    }
}
.nav__content__language {
    button {
        border: solid 2px white;
        color: white;
        padding: 10px 25px;
        white-space: nowrap;
        border-radius: 10px;
    }
}
.nav__content__login {
    button {
        border: solid 2px #f59120;
        color: black;
        font-weight: 700;
        background-color: #f59120;
        padding: 10px 25px;
        white-space: nowrap;
        border-radius: 10px;
        &:hover {
            color: #f59120;
        }
    }
}
.nav__button__textSize {
    font-size: 22px;
    @media screen and (max-width: 960px) {
        font-size: 1rem !important;
    }
}

// Media Queries
@include mixin1024 {
    .nav__logo {
        img {
            width: 60px;
        }
    }
    .nav__content__fontSize {
        div {
            min-width: 50px;
        }
    }
}
