.questionnaires__complete,
.questionnaires__error {
    max-width: 1200px;
    margin: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
}
.questionnaires__stage {
    text-decoration: underline;
}
.questionnaires__forms {
    .MuiFormGroup-root {
        flex-direction: column;
    }
}
.questionnaires__buttonContainer {
    background-color: white;
    border: 2px solid $orange;
    height: 100%;
    border-radius: 30px;
    &:hover {
        background-color: $orange;
        button {
            color: white !important;
        }
    }
}
.questionnaires__button {
    width: 100%;
    height: 100%;
    background-color: unset !important;
    box-shadow: unset !important;
    color: $orange !important;
    font-size: 50px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    transition: none !important;
}
.questionnaires__cta {
    border: 2px solid #f59120 !important;
    color: #f59120 !important;
    background-color: white !important;
    font-weight: bold !important;
    font-size: 1.25rem !important;
    border-radius: 10px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    &:hover {
        color: white !important;
        background-color: #f59120 !important;
        svg {
            color: white !important;
        }
    }
    svg {
        color: #f59120 !important;
    }
}
.questionnaire__bold {
    font-weight: 700;
    text-align: center;
}
