.prioritizedTopic {
    margin: 25px;
}
.prioritizedTopic__container {
    padding: 50px 0;
}
.prioritizedTopic__item {
    text-align: center;
}
.prioritizedTopic__itemContainer {
    // border: 1px solid $dark-blue;
    border-radius: 30px;
    // box-shadow: 2px 2px 10px 2px $dark-blue;
    background-color: $grey;
    height: 100%;
    padding: 2rem;
}
.prioritizedTopic__img {
    max-width: 150px;
    cursor: pointer;
    &.selection {
        animation: flash 2s infinite;
    }
}
@keyframes flash {
    50% {
        opacity: 0.5;
    }
}
.prioritizedTopic__topic__title {
    font-weight: bold;
}
.prioritizedTopics__dialog {
    .MuiImageList-root {
        gap: 20px !important;
    }
}
