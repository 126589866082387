.help {
    margin: 25px;
    @media screen and (max-width: 960px) {
        margin: 0;
    }
    form {
        .MuiFormControl-root {
            label {
                font-size: 1.25rem;
            }
            .MuiInput-root {
                .MuiSelect-select {
                    line-height: 2rem;
                }
                .MuiInput-input {
                    font-size: 1.5rem;
                }
            }
        }
    }
    .help__accordion {
        background-color: $grey !important;
        border-radius: 20px !important;
        padding: 10px;
        border: none !important;
        position: unset !important;
        box-shadow: none;
        .MuiButtonBase-root {
            .MuiAccordionSummary-expandIconWrapper {
                svg {
                    color: $orange;
                    font-size: 4rem;
                }
            }
        }
    }
    .help__faq__questions {
        font-size: 1.5rem;
    }
}
.MuiMenuItem-root {
    font-size: 1.5rem !important;
}
.help__subtitle {
    margin-bottom: 25px;
    margin-top: 25px;
}
.help__form {
    background-color: $grey75;
    padding: 2rem;
    border-radius: 20px !important;
}
.help__replayIntro {
    text-decoration: underline;
    cursor: pointer;
    margin-top: 40px;
    color: $sea-blue;
    text-decoration-color: $sea-blue;
}
.help__submit {
    border: 2px solid #f59120 !important;
    color: white !important;
    background-color: $orange !important;
    font-weight: bold !important;
    font-size: 1.25rem !important;
    border-radius: 10px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    &:hover {
        color: $orange !important;
        background-color: white !important;
        svg {
            color: white !important;
        }
    }
    svg {
        color: #f59120 !important;
    }
}
