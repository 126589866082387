.chapter__container {
    .MuiButton-containedPrimary {
        float: right;
        margin-bottom: 12px;
    }

    .MuiFormControl-root {
        padding-bottom: 12px;
    }
}
.completeChapter__snackbar {
    background-color: $dark-blue;
    padding: 10px;
    border-radius: 10px;
    color: white;
}
