.landing {
    background-color: #f5f5f5;
}

// hero
.landing__hero {
    background: linear-gradient(black 50%, $dark-blue);
}
.landing__hero__container {
    display: flex;
    gap: 100px;
    padding-top: 50px;
    padding-bottom: 100px;
    @media screen and (max-width: 600px) {
        gap: 25px;
        flex-direction: column-reverse;
    }
}
.landing__hero__content {
    color: white;
    align-self: center;
    h2 {
        margin-bottom: 25px;
    }
}
.landing__hero__image {
    width: 100%;
    img {
        width: 100%;
    }
}

// links
.landing__links {
    position: relative;
    top: -80px;
}
.landing__links__container {
    display: flex;
    gap: 5%;
}

// about
.landing__about {
    margin-bottom: 100px;
}
.landing__about__content {
    max-width: 800px;
    margin-bottom: 50px;
}
.landing__about__title {
    margin-bottom: 25px;
}
.landing__about__link {
    color: #0f76bb;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
}

// Media Queries
@include mixin1024 {
    .landing__links__container {
        flex-direction: column;
    }
}
