.searchbar__ {
    &title {
        padding-left: 20px;
        padding-top: 20px;
        color: $layout-text!important;
    }

    &container {
        // border: 1px solid black;
        // border-radius: 10px;
        display: grid;
        grid-template-columns: 4fr 3fr;
        align-items: center;
        margin-bottom: 20px;
    }

    &fields {
        display: grid;
        grid-template-columns: 4fr 2fr 1fr;
        align-items: center;
        > .search__element {
            margin: 10px;
            min-width: 60px;
        }
    }
}
