.nav__header__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
@media screen and (max-width: 960px) {
    .nav__header__container {
        justify-content: space-between;
        .MuiSvgIcon-root {
            color: $drawer-color;
        }
    }
}