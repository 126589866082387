// Gauge CSS
.gauge-wrapper {
    display: inline-block;
    width: auto;
    margin: 0 auto;
}

.gauge {
    background: #e7e7e7;
    box-shadow: 0 -3px 6px 2px rgba(0, 0, 0, 0.5);
    width: 300px;
    height: 150px;
    border-radius: 150px 150px 0 0 !important;
    position: relative;
    overflow: hidden;
}
.gauge.min-scaled {
    transform: scale(0.5);
}

.gauge-center {
    content: "";
    color: #fff;
    width: 60%;
    height: 60%;
    background: #15222e;
    border-radius: 100px 100px 0 0 !important;
    position: absolute;
    box-shadow: 0 -13px 15px -10px rgba(0, 0, 0, 0.28);
    right: 21%;
    bottom: 0;
    color: #fff;
    z-index: 10;
}

.gauge-center .label,
.gauge-center .number {
    display: block;
    width: 100%;
    text-align: center;
    border: 0 !important;
}
.gauge-center .number {
    font-size: 1.2em;
    margin-top: 45px;
}
.gauge-label {
    display: flex;
    justify-content: space-between;
}

.needle {
    width: 130px;
    height: 20px;
    background: #15222e;
    border-bottom-left-radius: 100% !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 100% !important;
    border-top-right-radius: 5px !important;
    position: absolute;
    bottom: -6.5px;
    left: 20px;
    // transform-origin: 100% 4px;
    transform: rotate(0deg);
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.38);
    // display: none;
    z-index: 9;
}

.four.rischio3 .needle {
    // animation: fourspeed 2s 1 both;
    // animation-delay: 1s;
    display: block;
}

.slice-colors {
    height: 100%;
}

.slice-colors .st {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border: 95px solid transparent;
}

.four .slice-colors .st.slice-item:nth-child(2) {
    border-top: 120px rgb(235, 235, 19) solid;
    border-right: 64px rgb(235, 235, 19) solid;
    background-color: #1eaa59;
}

.four .slice-colors .st.slice-item:nth-child(4) {
    left: 50%;
    border-bottom: 175px #e84c3d solid;
    border-right: 75px #e84c3d solid;
    background-color: rgb(235, 235, 19);
}

// @keyframes fourspeed {
//     0% {
//         transform: rotate(0);
//     }
//     100% {
//         transform: rotate(30deg);
//     }
// }

// Card CSS
.gaugeCard__container {
    background-color: $grey;
    padding: 30px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.gaugeCard__description {
    display: flex;
    margin-bottom: 30px;
    img {
        width: 100px;
        height: 100px;
        @include extra-large {
            width: 75px;
            height: 75px;
        }
        @include large {
            width: 100px;
            height: 100px;
        }
    }
}
.gaugeCard__title {
    margin-left: 20px;
}

.gaugeCard__lowRisk {
    background-color: #6dbd4520;
    // border: 1px solid #6dbd45;
}
.gaugeCard__moderateRisk {
    background-color: #f6922020;
    // border: 1px solid #f6922045;
}
.gaugeCard__highRisk {
    background-color: #eb353920;
    // border: 1px solid #eb353945;
}
.gauge__container {
    display: flex;
    align-items: center;
    @include extra-large {
        transform: scale(0.75);
    }
    @include large {
        transform: scale(1);
    }
}
.gaugeCard__title {
    display: inline-grid;
    h3,
    h5 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
