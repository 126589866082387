.topics__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        display: flex;
        align-content: center;
        svg {
            font-size: 30px;
            margin-right: 5px;
            color: $orange;
        }
    }
}
