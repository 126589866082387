.profile__container {
    margin: 25px;
}
.profile__summary {
    margin-bottom: 30px;
    h6 {
        margin-bottom: 10px;
    }
}
.profile__thumbs {
    color: $green;
}
.profile__careful {
    color: $orange;
}
.profile__content {
    padding-bottom: 1rem;
}
