.topic__header {
    display: flex;
    justify-content: space-between;
    img {
        width: 125px;
        height: 125px;
        margin-right: 20px;
    }
}
.topic__header__title {
    display: flex;
}
.topic__header__back {
    text-decoration: underline;
    color: $dark-blue;
    &:hover {
        color: $dark-blue;
        text-decoration: underline;
    }
}
.topic__header__legend {
    display: flex;
    flex-direction: column;
}
.topic__header__legend__item {
    display: flex;
    p {
        align-self: center;
    }
}
