$primary: #0f5d77;
$secondary: #f59120;
$drawer-color: #f5f5f5;
$splashscreen-background: #f5f5f5;
$page-background: #f5f5f5;
$appBar-color: #f5f5f5;
$layout-text: #000;
$main-background: #ececec;
$dark-blue: #003a51;
$dark-blue20: #003a5120;
$sea-blue: #0f5d77;
$grey: #e6e6f0;
$grey75: #e6e6f075;
$dark-grey: #6a6969;
$orange: #f59120;
$green: #119111;

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&family=Roboto+Condensed:wght@400;700&display=swap");

$Poppins: "Poppins", sans-serif;
$Roboto: "Roboto Condensed", sans-serif;

// Mixins
@mixin mixin1024 {
    @media (max-width: 1024px) {
        @content;
    }
}
