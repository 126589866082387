.taskCard {
    background-color: $grey;
    // border: solid 1px #777b81;
    border-radius: 20px;
    padding: 25px;
    margin: 15px 0;
    display: flex;
    align-items: center;
    position: relative;
}
.taskCard__logo {
    padding: 15px;
    border-radius: 70px;
    display: flex;
    @media screen and (max-width: 960px) {
        padding: 0;
    }
    img {
        max-width: 100px;
        @media screen and (max-width: 960px) {
            max-width: 50px;
        }
    }
}
.taskCard__content {
    width: 90%;
    margin-left: 25px;
}
.taskCard__topRightCorner {
    position: absolute;
    top: 7px;
    right: 25px;
    font-size: 46px;
    @media screen and (max-width: 960px) {
        font-size: 30px;
        right: 10px;
    }
}
.taskCard__locked {
    width: 45px;
    height: 45px;
}
.taskCard__fullHearth {
    color: red;
}
.taskCard__fullHearth__legend {
    color: red;
    font-size: 46px;
    line-height: 40px;
}
.taskCard__outlineHeart__legend {
    font-size: 46px;
    line-height: 40px;
}
.taskCard__pointer {
    cursor: pointer;
}
.taskCard__star {
    color: $orange;
}
.taskCard__firstLine {
    display: flex;
}
.taskCard__separation {
    font-weight: 400;
    font-size: 1.25em;
}
.progressbar {
    height: 8px !important;
}
.progressbar__container {
    justify-content: space-between;
    position: absolute;
    bottom: 65px;
    right: 17px;
}
.progressbar {
    background-color: rgba(34, 233, 34, 00) !important;
    height: 10px;
    border: solid 1px black;
    span {
        background-color: black;
    }
}
.progressbar__complete {
    position: relative;
    &::after {
        text-align: center;
        content: "✔";
        position: absolute;
        right: 11px;
        bottom: -15px;
        color: rgb(34, 233, 34);
        font-size: 28px;
        background-color: black;
        border-radius: 40px;
        width: 40px;
        height: 40px;
    }
}
.progressbar__complete__legend {
    position: relative;
    height: 50px;
    width: 50px;
    &::after {
        text-align: center;
        content: "✔";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: rgb(34, 233, 34);
        font-size: 28px;
        background-color: black;
        border-radius: 40px;
        width: 40px;
        height: 40px;
    }
}
.progressbar__incomplete {
    position: relative;
    &::after {
        text-align: center;
        content: "✔";
        position: absolute;
        right: 11px;
        bottom: -15px;
        color: black;
        border: 2px solid black;
        font-size: 28px;
        background-color: rgb(230, 230, 240);
        border-radius: 40px;
        width: 40px;
        height: 40px;
    }
}
.progressbar__incomplete__legend {
    position: relative;
    height: 50px;
    width: 50px;
    &::after {
        text-align: center;
        content: "✔";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: black;
        border: 2px solid black;
        font-size: 28px;
        background-color: white;
        border-radius: 40px;
        width: 40px;
        height: 40px;
    }
}
.progressbar__bottom {
    &::after {
        bottom: -54px;
    }
}
