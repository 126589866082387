.goals {
    margin: 25px;
    form {
        .MuiFormControl-root {
            label {
                font-size: 1.25rem;
            }
            .MuiInput-root {
                input {
                    font-size: 1.25rem;
                }
            }
        }
        .goals__section {
            fieldset {
                .MuiFormGroup-root {
                    label {
                        .MuiTypography-root {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}
.goals__section {
    margin-bottom: 25px;
}
.goals__subsection {
    text-decoration: underline;
}
.goals__questions {
    margin-bottom: 1rem;
}
.goals__icons {
    max-width: 125px;
}
.goals__itemContainer {
    // border: 1px solid $dark-blue;
    border-radius: 30px;
    // box-shadow: 2px 2px 10px 2px $dark-blue;
    background-color: $grey;
    height: 100%;
    padding: 20px 0;
}
.goals__boxPadding {
    padding: 2rem 2rem;
}

.goals__previousWeek__description {
    overflow-wrap: anywhere;
    padding: 10px;
}
