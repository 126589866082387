.riskDiagram__header {
    display: flex;
    align-items: center;
    min-height: 2.625rem;

}
.riskDiagram__logo {
    max-width: 35px;
}
.riskDiagram__title {
    padding-left: 10px;
    font-size: 1.5rem;
    line-height: 1.5rem;
}
.riskDiagram__container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
    height: 100%;
}
.riskDiagram__content {
    width: 20px;
    border-radius: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
}
.riskDiagram__stage {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
}