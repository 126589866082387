.notebook__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        display: flex;
        align-content: center;
    }
    svg {
        font-size: 2.5rem;
    }
}
.notebook__popper {
    z-index: 5;
}
