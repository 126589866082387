.topBarForm {
    background-color: $dark-blue;
    max-height: 115px;
    img {
        margin: 1.5em;
        max-height: 85px;
    }
    display: flex;
    justify-content: space-between;
}
.topBarForm__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 4px;
    div {
        padding: 10px;
    }
}
.topBarForm__right.active {
    background-color: $dark-blue;
    left: 0;
    opacity: 1;
    transition: all 0.5s, ease;
    z-index: 99;
}

.hbg-menu {
    display: block;
    position: absolute;
    right: -55px;
    top: -70px;
    transform: translate(-100%, 100%);
    color: white;
    height: 75px;
    font-size: 75px;
    cursor: pointer;
}
@include large {
    .topBarForm__right {
        display: flex;
        // flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: auto;
        padding: 15px 20px;
        position: absolute;
        top: 90px;
        right: 100%;
        opacity: 1;
        transition: all 0.5s ease;
    }
}
