.landingCard {
    background-color: $grey;
    width: 33%;
    border-radius: 30px;
}
.landingCard__container {
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    padding: 20px 0px;
    height: 100%;
}
.landingCard__title {
    a {
        text-decoration: none;
        h3 {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            color: black;
            svg {
                color: orange;
            }
        }
        &:hover {
            text-decoration: underline;
            text-decoration-color: black;
            text-decoration-thickness: 2px;
        }
    } 
}
.landingCard__text {
    height: 100%;
}

// Media Queries
@include mixin1024 {
    .landingCard {
        width: 100%;
        margin: 15px 0;
    }
}