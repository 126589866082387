.mainLayout__container {
    display: flex;
    background-color: $main-background;
    .mainLayout__content {
        height: 100vh;
        overflow: auto;
        flex-grow: 1;
    }
    .mainLayout__appBarSpacer {
        min-height: 82.5px;
    }
    .mainLayout__paper {
        padding: 20px 50px !important;
    }
    .MuiDrawer-paperAnchorDockedLeft {
        background-color: $drawer-color;
    }
    .MuiAppBar-root {
        background-color: $dark-blue !important;
    }
    .MuiPaper-elevation4 {
        box-shadow: none;
    }
    .MuiDrawer-paperAnchorDockedLeft {
        border-right: none;
    }
    .mainLayout__info {
        padding: 25px;
    }
}

.mainLayout__smallscreen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $dark-blue;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999999;
    overflow: hidden;
    img {
        max-width: 25%;
        margin-bottom: 100px;
    }
    color: white;
    padding: 2rem;
    text-align: center;
    h3 {
        margin-bottom: 20px;
    }
}
