body {
    background-color: $splashscreen-background;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a,
a:hover,
a:active {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer !important;
}

// change theme
.inverse {
    filter: invert(100%);
    .nofilterimg {
        filter: invert(100%) !important;
    }
    .cancelnofilterimg {
        filter: invert(100%);
    }
}
// Mixins
@mixin medium {
    @media screen and (max-width: 900px) {
        @content;
    }
}
@mixin large {
    @media screen and (max-width: 1200px) {
        @content;
    }
}
@mixin extra-large {
    @media screen and (max-width: 1536px) {
        @content;
    }
}

// Set font-family
h1,
h2,
h3,
h4 {
    font-family: $Roboto;
    margin: 0;
    font-weight: 400;
}
h5,
h6,
p,
a {
    font-family: $Poppins;
    font-weight: 400;
    margin: 0;
}
// .font__regular {
//     font-weight: 400;
// }

// Font scaling
/* default */
html {
    font-size: 16px;
}
@media screen and (min-width: 1920px) {
    /* xl */
    html {
        font-size: 16px;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
    /* lg */
    html {
        font-size: 14px;
    }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
    /* md */
    html {
        font-size: 14px;
    }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
    /* sm */
    html {
        font-size: 14px;
    }
}
@media screen and (min-width: 0) and (max-width: 599px) {
    /* xs */
    html {
        font-size: 12px;
    }
}

// Change font size
.font1 {
    // Fonts
    @include extra-large {
        h1 {
            font-size: 3.25rem;
            line-height: 3.75rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h2 {
            font-size: 2.75rem;
            line-height: 3.25rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h3 {
            font-size: 2.25rem;
            line-height: 2.75rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h4 {
            font-size: 2.125rem;
            line-height: 2.625rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h5 {
            font-size: 1.4375rem;
            line-height: 1.9375rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h6 {
            font-size: 1.25rem;
            line-height: 1.5rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        p {
            font-size: 1rem;
            line-height: 1.25rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        a {
            font-size: 1rem;
            line-height: 1.25rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
    }
    h1 {
        font-size: 3.5rem;
        line-height: 3.5rem;
        @media screen and (max-width: 960px) {
            font-size: 2.25rem;
            line-height: 2.5rem;
        }
    }
    h2 {
        font-size: 3rem;
        line-height: 3rem;
        @media screen and (max-width: 960px) {
            font-size: 2rem;
            line-height: 2.1rem;
        }
    }
    h3 {
        font-size: 2.5rem;
        line-height: 3rem;
        @media screen and (max-width: 960px) {
            font-size: 1.75rem;
            line-height: 1.95rem;
        }
    }
    h4 {
        font-size: 2.375rem;
        line-height: 2.875rem;
        @media screen and (max-width: 960px) {
            font-size: 1.5rem;
            line-height: 1.75rem;
        }
    }
    h5 {
        font-size: 1.6875rem;
        line-height: 2.1875rem;
        @media screen and (max-width: 960px) {
            font-size: 1.25rem;
            line-height: 1.35rem;
        }
    }
    h6 {
        font-size: 1.5rem;
        line-height: 2rem;
        @media screen and (max-width: 960px) {
            font-size: 1rem;
            line-height: 1.25rem;
        }
    }
    p {
        font-size: 1.25rem;
        line-height: 1.5rem;
        @media screen and (max-width: 960px) {
            font-size: 1rem;
            line-height: 1.15rem;
        }
    }
    a {
        font-size: 1.25rem;
        line-height: 1.5rem;
        @media screen and (max-width: 960px) {
            font-size: 1rem;
            line-height: 1.15rem;
        }
    }
}
.font2 {
    // Fonts
    @include extra-large {
        h1 {
            font-size: 4.25rem;
            line-height: 4.75rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h2 {
            font-size: 4rem;
            line-height: 4.5rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h3 {
            font-size: 3.475rem;
            line-height: 3.975rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h4 {
            font-size: 2.625rem;
            line-height: 3.125rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h5 {
            font-size: 1.9375rem;
            line-height: 2.4375rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h6 {
            font-size: 1.75rem;
            line-height: 2.25rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        p {
            font-size: 1.5rem;
            line-height: 2rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        a {
            font-size: 1.5rem;
            line-height: 2rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
    }
    h1 {
        font-size: 4.5rem;
        line-height: 5rem;
        @media screen and (max-width: 960px) {
            font-size: 2.5rem;
            line-height: 2.75rem;
        }
    }
    h2 {
        font-size: 4.25rem;
        line-height: 4.75rem;
        @media screen and (max-width: 960px) {
            font-size: 2.25rem;
            line-height: 2.5rem;
        }
    }
    h3 {
        font-size: 3.625rem;
        line-height: 4.125rem;
        @media screen and (max-width: 960px) {
            font-size: 2rem;
            line-height: 2.25rem;
        }
    }
    h4 {
        font-size: 2.875rem;
        line-height: 3.375rem;
        @media screen and (max-width: 960px) {
            font-size: 1.75rem;
            line-height: 2rem;
        }
    }
    h5 {
        font-size: 2.1875rem;
        line-height: 2.6875rem;
        @media screen and (max-width: 960px) {
            font-size: 1.5rem;
            line-height: 1.75rem;
        }
    }
    h6 {
        font-size: 2rem;
        line-height: 2.5rem;
        @media screen and (max-width: 960px) {
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
    }
    p {
        font-size: 1.75rem;
        line-height: 2.25rem;
        @media screen and (max-width: 960px) {
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
    }
    a {
        font-size: 1.75rem;
        line-height: 2.25rem;
        @media screen and (max-width: 960px) {
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
    }
}
.font3 {
    // Fonts
    @include extra-large {
        h1 {
            font-size: 4.75rem;
            line-height: 5.25rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h2 {
            font-size: 4.5rem;
            line-height: 5rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h3 {
            font-size: 4.875rem;
            line-height: 5.375rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h4 {
            font-size: 3.125rem;
            line-height: 3.625rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h5 {
            font-size: 2.4375rem;
            line-height: 2.9375rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        h6 {
            font-size: 2.25rem;
            line-height: 2.75rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        p {
            font-size: 2rem;
            line-height: 2.5rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
        a {
            font-size: 2rem;
            line-height: 2.5rem;
            @media screen and (max-width: 960px) {
                font-size: 3.5rem;
                line-height: 3.5rem;
            }
        }
    }
    h1 {
        font-size: 5rem;
        line-height: 5.5rem;
        @media screen and (max-width: 960px) {
            font-size: 3rem;
            line-height: 3.5rem;
        }
    }
    h2 {
        font-size: 4.75rem;
        line-height: 5.25rem;
        @media screen and (max-width: 960px) {
            font-size: 2.75rem;
            line-height: 3rem;
        }
    }
    h3 {
        font-size: 4.125rem;
        line-height: 4.625rem;
        @media screen and (max-width: 960px) {
            font-size: 2.5rem;
            line-height: 2.7rem;
        }
    }
    h4 {
        font-size: 3.375rem;
        line-height: 3.875rem;
        @media screen and (max-width: 960px) {
            font-size: 2.25rem;
            line-height: 2.5rem;
        }
    }
    h5 {
        font-size: 2.6875rem;
        line-height: 3.1875rem;
        @media screen and (max-width: 960px) {
            font-size: 2rem;
            line-height: 2.25rem;
        }
    }
    h6 {
        font-size: 2.5rem;
        line-height: 3rem;
        @media screen and (max-width: 960px) {
            font-size: 1.75rem;
            line-height: 2rem;
        }
    }
    p {
        font-size: 2.25rem;
        line-height: 2.75rem;
        @media screen and (max-width: 960px) {
            font-size: 1.5rem;
            line-height: 1.75rem;
        }
    }
    a {
        font-size: 2.25rem;
        line-height: 2.75rem;
        @media screen and (max-width: 960px) {
            font-size: 1.5rem;
            line-height: 1.75rem;
        }
    }
}

// Spacing
.content__spacing {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0px 35px;
}

// Custom Scrollbar
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 20px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $primary;
}

// .MuiPaper-elevation1 {
//     padding: 20px !important;
//     border-radius: 5px !important;

//     -webkit-box-shadow: rgba(0, 0, 0, 0.01) 0 1px 1px !important;
//     -moz-box-shadow: rgba(0, 0, 0, 0.01) 0 1px 1px !important;
//     box-shadow: rgba(0, 0, 0, 0.01) 0 1px 1px !important;
// }

.MuiPaper-root {
    background-color: $page-background !important;
}

.MuiListSubheader-root {
    background-color: $drawer-color !important;
}

.error__text {
    color: #d32f2f;
    font-style: italic;
    font-size: 12px;
    padding-left: 10px;
}

.MuiFormHelperText-root.Mui-error {
    color: #d32f2f;
    // font-style: italic;
    font-size: 12px;
    padding-left: 10px;
}

// Mui submit button
.MuiButton-root {
    font-family: $Roboto !important;
    letter-spacing: 1.2px !important;
    transition: none !important;
}

// Snackbar
.point__snackbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark-blue;
    padding: 10px;
    border-radius: 10px;
    color: white;
    padding-right: 30px;
    img {
        max-width: 50px;
        margin-right: 10px;
    }
}

.nopaddingtop {
    padding-top: 0 !important;
}

// Confirmation dialog
.MuiBackdrop-root {
    background-color: transparent !important;
    .MuiPaper-root-MuiDialog-paper {
        box-shadow: 0px !important;
    }
}
